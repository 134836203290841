.listUsers {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-top: var(--space-xxs) solid var(--outline-gray);
}

.header {
  display: flex;
  padding: 1.1rem var(--space-md) 1.1rem;
  border-bottom: 1px solid var(--outline-gray);
}

.title {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  height: 1.35rem;
  font-family: var(--font-primary);
}

.content {
  flex: 1 0 auto;
  flex-direction: column;
  display: flex;
  width: 100%;
  margin: 0 auto var(--space-sm);
  padding: 0 var(--space-md);
}

.contentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-sm) 0;
}

.contentTitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
}

.error {
  position: relative;
  margin-top: var(--space-md);
  color: var(--red-text);
}

.reinviteButton {
  position: relative;
}

.deleteButton {
  position: relative;
}

.errorBtnMsg {
  color: var(--red-text);
}
.successBtnMsg {
  color: var(--green-text);
}

.errorBtnMsg,
.successBtnMsg {
  position: absolute;
  top: 0;
  left: 0;
}

.actions {
}

.actions > div:first-child {
  margin-bottom: var(--space-xs);
}
