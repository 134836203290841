/* Set content to 12 column grid */
.grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  /* grid-column-gap: var(--grid-column-gap); */
  max-width: var(--grid-max-width);
  width: 100%;
  margin: auto;

  padding: 0 var(--space-xs);
  /* padding: 0 calc(var(--grid-max-width) * 0.04); 4% gutter */
}

.gapGrid {
  composes: grid;
  grid-column-gap: var(--grid-column-gap);
}

/*Debug styles to show grid overlay */
.grid.isDebug {
  --column-color: red;
  --gutter-color: transparent;
  /* Calculate size of one column + gutter relative to parent (100% width) */
  --column-and-gutter-width: calc(
    (100% + var(--grid-column-gap)) / var(--grid-columns)
  );

  /* Calculate size of individual column by subtracting gutter size */
  --column-width: calc(var(--column-and-gutter-width) - 2.5%);
  padding: 0 calc(var(--grid-max-width) * 0.04); /* 4% gutter */
  position: relative;
}

.grid.isDebug::after {
  content: "";
  position: absolute;
  top: 0;

  /* 4% padding */
  left: calc(var(--grid-max-width) * 0.04);
  width: calc(var(--grid-max-width) * 0.92);

  /* left: 0%; */
  /* width: 100%; */

  height: 100%;
  pointer-events: none;
  opacity: 0.1;

  /* prettier-ignore */
  /* Repeating gradient background to draw stripes */
  /* First/second step is from {0} -> {--column-width} as column color (width of one column) */
  /* Third/fourth step is from {--column-width} -> {--column-and-gutter-width} (width of one gutter) */
  background-image: repeating-linear-gradient(
    to right,
    var(--column-color) 0,
    var(--column-color) var(--column-width),
    var(--gutter-color) var(--column-width),
    var(--gutter-color) var(--column-and-gutter-width)
  );
}

.grid > * {
  grid-column: 6 span;
}

.grid .gridCol12 {
  grid-column: 12 span;
}

.grid .gridCol6 {
  grid-column: 6 span;
}

.grid .gridCol4 {
  grid-column: 4 span;
}

.grid .gridCol3 {
  grid-column: 3 span;
}

.grid .gridCol2 {
  grid-column: 2 span;
}

.oneColGrid {
  composes: grid;
}

.oneColGrid > * {
  grid-column: 12 span;
}
