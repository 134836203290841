.icon {
  width: 1.333rem;
  height: 1.333rem;
}

.iconLarge {
  width: 2.5rem;
  height: 2.5rem;
}

.iconSmall {
  width: 1.111rem;
  height: 1.111rem;
}

.iconTiny {
  width: 0.888rem;
  height: 0.888rem;
}

.iconMicro {
  width: 0.5rem;
  height: 0.5rem;
}
