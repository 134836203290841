.associatedTitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  margin-top: var(--space-lg);
  margin-bottom: var(--space-sm);
  font-family: var(--font-primary);
}

.noHeaderSection {
}

.viewToggle {
  margin-bottom: calc(-1 * var(--space-xl));
}

.sectionHeader {
  composes: sectionHeader from "../studies.module.css";
  display: flex;
  margin: var(--space-sm) var(--space-sm) 0;
  padding: 0 0 var(--space-sm);
  border-bottom: 1px solid var(--outline-gray);
  min-height: 8rem;
}

.description,
.findings {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  position: relative;
  line-height: 1.4;
  color: var(--primary-gray);
  flex: 0 1 50%;
}

.description p {
  margin-right: var(--space-lg);
}

.findings strong {
  color: var(--primary-dark);
}

.title {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-xs);
  font-weight: 600;
  font-family: var(--font-primary);
  color: var(--primary-dark);
}

.controls {
  composes: controls from "../studies.module.css";
  flex: 1 0 auto;
  align-items: flex-end;
  justify-content: flex-end;
}

.controls > div:not(:last-child) {
  margin-bottom: var(--space-sm);
}

.content {
  composes: sectionContent from "../studies.module.css";
  margin-top: var(--space-lg);
}

.gridSection {
  margin-bottom: var(--space-lg);
}

.gridTitle {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  font-weight: 600;
  font-family: var(--font-primary);
}

.gridDescription {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  margin: var(--space-xs) 0;
  color: var(--primary-gray);
}

.gridContent {
  position: relative;
  margin-top: calc(
    -1 * var(--space-xxxl)
  ); /* compensate for grid below marginTop */
}

.gridCta {
  display: flex;
  justify-content: flex-end;
  margin: var(--space-sm) 0;
}
.gridCta > * {
  flex-basis: 15rem;
}

.topProteinCell {
  color: var(--blue-text);
  text-decoration: none;
}
.topProteinCell:hover {
  text-decoration: underline;
}
