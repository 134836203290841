.reports {
}

.content {
  composes: content from "../metrics.module.css";
}

.title {
  composes: title from "../metrics.module.css";
}

.sectionTitle {
  composes: sectionTitle from "../metrics.module.css";
  margin-bottom: var(--space-xxxl);
}

.indicationReport {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto -1px;
  padding-bottom: var(--space-xxxl);
  border-top: 1px solid var(--outline-gray);
  border-bottom: 1px solid var(--outline-gray);
}

.indicationReportTitle {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  
}

.indicationReportHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--space-sm) 0;
}

.indicationReportDate {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  color: var(--primary-gray);
}

.indicationReportImage {
  margin-bottom: var(--space-sm);
}
