.sidebar {
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.userIconsWrapper {
}

.userIconsWrapper > div {
  border-top: 1px solid var(--outline-gray);
}

.submenu {
  position: absolute;
  top: -1px;
  left: 100%;
  z-index: 100;
}

.users {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  display: block;
  padding: var(--space-xs);
  cursor: pointer;
  background-color: var(--primary-light);
  text-decoration: none;
  text-transform: uppercase;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--outline-gray);
}

.link {
  display: block;
  text-align: center;
  padding: var(--space-md);
  color: var(--primary-dark);
  background-color: var(--primary-light);
  transition: 0.2s color, 0.2s background-color;
  cursor: pointer;
}

.disabledLink {
  composes: link;
  cursor: not-allowed;
}
.disabledLink path {
  fill: var(--primary-gray) !important;
}

.logoLink {
  composes: link;
  padding: 1.222rem 0.666rem 0.777rem;
}

.logoLink > div {
  width: 2.666rem;
  height: 2rem;
}

.link > svg {
  display: block;
}

.link path,
.link rect {
  fill: var(--primary-dark);
}

.link:hover path,
.link:hover rect {
  fill: var(--primary-gray);
}

.linkActive {
  composes: link;
  position: relative;
}

.linkActive path,
.linkActive rect {
  fill: var(--primary-blue) !important;
}

.linkActive:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.222rem;
  background-color: var(--primary-blue);
}

.users {
  display: block;
  padding: var(--space-xs);
}

.users circle,
.users path {
  fill: var(--primary-dark);
}
