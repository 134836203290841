.tooltip {
  position: relative;
  display: block;
  cursor: pointer;
}
.tooltip:hover {
  opacity: 0.75;
  /* color: var(--primary-gray) !important; */
  /* filter: brightness(85%); */
}

.popup {
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  padding: var(--space-xxs) var(--space-xs) var(--space-xs);
  line-height: 0.85rem;
  background: var(--primary-light);
  color: var(--primary-dark);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: var(--space-xxxs);
  white-space: nowrap;
}

.popupMultiline {
  width: 10rem;
  white-space: normal;
}

.popup:after {
  position: absolute;
  top: -13px;
  right: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: transparent;
  border-bottom-color: var(--primary-light);
  border-width: var(--space-xxs);
  transform: translate(50%, 0);
}

.icon rect,
.icon circle {
  fill: var(--primary-dark);
}

.icon path {
  fill: var(--primary-light);
}
