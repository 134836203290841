.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--primary-dark);
}

.wrapper svg {
  position: absolute;
  top: 50%;
  right: var(--space-sm);
  transform: translate(0, -50%);
  pointer-events: none;
}

.wrapper svg path {
  fill: var(--primary-dark);
}

.wrapper:hover svg path {
  fill: var(--primary-light);
}

.label {
  white-space: nowrap;
  margin-right: var(--space-xs);
}

.select {
  appearance: none;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: var(--space-sm) var(--space-xl) var(--space-sm) var(--space-md);
  border: 1px solid var(--outline-gray);
  background-color: var(--primary-light);
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  cursor: pointer;
}

.wrapper.primary select {
  background-color: var(--primary-dark);
  color: var(--primary-light);
}

.wrapper:hover select {
  background-color: var(--primary-dark);
  color: var(--primary-light);
}

/* small theme */
.small {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
}

.small .select {
  padding: calc(var(--space-xs) - 1px) var(--space-xl)
    calc(var(--space-xs) - 1px) var(--space-xs);
}

/* primary theme */
.wrapper.primary:hover select {
  background-color: var(--primary-light);
  color: var(--primary-dark);
}

.wrapper.primary svg path {
  fill: var(--primary-light);
}

.wrapper.primary:hover svg path {
  fill: var(--primary-dark);
}

/* disabled */
.wrapper.disabled {
  color: var(--primary-light);
}
.wrapper.disabled select {
  background-color: var(--primary-gray);
  border: 1px solid var(--primary-gray);
}

.wrapper.disabled svg path {
  fill: var(--primary-light);
}

.disabled .select {
  cursor: not-allowed;
}

/* subtle theme */
.wrapper.subtle select {
  border: 0;
}
.wrapper.subtle svg {
  display: none;
}
.wrapper.subtle:hover {
  color: var(--primary-dark);
}
.wrapper.subtle:hover select {
  background-color: var(--primary-light);
}
.wrapper.subtle .select select {
  padding: var(--space-xs);
}
