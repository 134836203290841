.popup {
  max-width: 24rem;
}

.title {
  composes: text--xl from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-sm);
  font-family: var(--font-primary);
}

.closeBtn button {
  position: relative;
  top: 0.5rem;
  right: -0.5rem;
}

.title svg {
  position: relative;
  top: 2px;
  margin-left: var(--space-xs);
}

.title path {
  fill: var(--primary-dark);
}

.btns {
  display: flex;
  margin: var(--space-xs) 0;
}

.btns > * {
  width: var(-);
}

.list {
  max-height: 50vh;
  overflow: auto;
  padding: var(--space-sm) 0;
  border: 1px solid var(--outline-gray);
  border-left: 0;
  border-right: 0;
}

.listItem {
  display: "flex";
  align-items: "center";
}

.column {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  cursor: pointer;
}

.columnSpan {
  margin-left: var(--space-xs);
}

.footer {
  display: flex;
  margin: var(--space-sm) calc(-1 * var(--space-sm)) 0;
}

.footer * {
  margin: 0 var(--space-sm);
}
