.compounds {
}

.compound {
  padding: var(--space-sm);
  margin-bottom: var(--space-sm);
  background: var(--background-gray);
}

.compoundForm {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.compoundForm > div {
  flex: 1 0 auto;
}
.compoundForm > div:last-child {
  flex-basis: 66.6%;
  padding-left: var(--space-sm);
}

.formSectionTitle {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  padding: var(--space-sm) 0;
}

.compoundFormSection {
  composes: formSection from "../create.module.css";
}

.formTitle {
  composes: formTitle from "../create.module.css";
}

.compoundFormTitle {
  composes: formTitle;
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
}

.compoundEmpty {
  composes: endpointEmpty from "../endpoints/endpoints.module.css";
  min-height: 5rem;
}

.compounds input {
  color: var(--primary-light) !important;
  background-color: var(--primary-gray) !important;
  padding: var(--space-xxs) var(--space-sm);
  font-size: 0.666rem;
}

.compoundFormRemoveBtn {
  composes: endpointFormRemoveBtn from "../endpoints/endpoints.module.css";
}

.addCompoundBtn {
  composes: addEndpointBtn from "../endpoints/endpoints.module.css";
}

.smilesInput {
  position: relative;
}

.invalidInput {
  border: 2px solid var(--red) !important;
}

.validInput {
  border: 2px solid var(--green) !important;
}

.preloader {
  position: absolute;
  top: 50%;
  right: var(--space-sm);
}

.errorMsg {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  color: var(--red-text);
  position: absolute;
}
