@font-face {
  font-family: "WhyteInktrap";
  src: url("./fonts/ABCWhyteInktrap-Medium.woff2") format("woff2"),
    url("./fonts/ABCWhyteInktrap-Medium.woff") format("woff");
}

/* inter-600 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./fonts/inter-v12-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/inter-v12-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  font-family: var(--font-primary);
  line-height: var(--line-height);
  font-weight: 600;
  color: var(--primary-dark);
  background-color: var(--primary-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.02em;
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-secondary);
  font-weight: 500;
}

h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: var(--text-xxxxl);
}

h2 {
  font-size: var(--text-xxxl);
}

h3 {
  font-size: var(--text-xxl);
}

h4 {
  font-size: var(--text-xl);
}

h5 {
  font-size: var(--text-lg);
}

h6 {
  font-size: var(--text-md);
}

small {
  font-size: var(--text-sm);
}

/* reset letter-spacing for anything but default text */
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
  line-height: 1.2;
}

@media only screen and (min-width: 1400px) {
  .text--xxxxl,
  .text--xxxl,
  .text--xxl,
  .text--xl,
  .text--lg,
  .text--sm,
  .text--xs {
    line-height: 1.15;
  }
}
