.hierarchyBreadcrumbs {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.hierarchyBreadcrumbs a {
  color: var(--blue-text);
  text-decoration: none;
}

.hierarchyBreadcrumbs a:hover {
  text-decoration: underline;
}

.item {
  position: relative;
  padding-right: var(--space-lg);
}

.item:not(:last-child)::after {
  content: "->";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
