.studyBox {
  position: relative;
  padding: var(--space-xs);
  border: 1px solid var(--outline-gray);
  background-color: var(--background-gray);
}

.header,
.footer {
  display: flex;
  justify-content: space-between;
}

.header {
  margin-bottom: var(--space-md);
  margin-right: var(--space-xl);
}

.title {
  composes: text--xl from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
}

.meta {
  display: flex;
  justify-content: space-between;
  color: var(--primary-gray);
}

.tags {
  display: flex;
  margin: var(--space-xxs) 0;
}
.tags > * {
  margin-right: var(--space-xxs);
}

.tag {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  padding: var(--space-xxs);
  border: 1px solid var(--primary-dark);
}

.dosage,
.details,
.meta,
.footer {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
}

.description {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  overflow: hidden;
  margin: var(--space-md) 0;
  position: relative;
  min-height: 3.125rem; /* for 3 lines height to align boxes */
  line-height: 1.4;
  color: var(--primary-gray);
}

.description > p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.description strong {
  color: var(--primary-dark);
}

.footer {
  display: flex;
  align-items: flex-end;
}

.footerBtns {
  display: flex;
}

.footerBtns > * {
  margin-left: var(--space-xs);
}

.topBtns {
  position: absolute;
  top: var(--space-xs);
  right: var(--space-xs);
}

.topBtns > * {
  margin-left: var(--space-xxs);
}

.topBtn {
  appearance: none;
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.topBtn:hover {
  opacity: 1;
}
.topBtn path {
  fill: var(--primary-dark);
}
