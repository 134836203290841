/* grid */

:root {
  /* --grid-unit: 8px; */

  --grid-columns: 12;
  --grid-gutters: calc(var(--grid-columns) - 1);
  --grid-gutter-percentage: 0.025;

  --grid-column-gap: calc(100% * var(--grid-gutter-percentage));

  /* crazy large max width just for edge cases */
  --grid-max-width: 150rem;

  /* grid size - gutter percentage*/
  /* --grid-static-gutter-width: calc(100vw * var(--grid-gutter-percentage));

  /* (grid size - gutter width * gutter count) / grid columns  */
  /* prettier-ignore */
  /* --grid-static-column-width: calc(
    (100vw - var(--grid-static-gutter-width) * var(--grid-gutters)) / var(--grid-columns)
  ); */
}

/* colors */

:root {
  /* */
  --global-dark: #1d1d1d;
  --global-light: #ffffff;

  /* colors which change with modes */
  --primary-dark: #1d1d1d;
  --primary-gray: #767676;
  --outline-gray: #d3d3d9;
  --shape-gray: #cecece;
  --background-gray: #f9f9f9;
  --primary-blue: #1343ee;
  --blue-text: #1343ee;
  --purple: #a54ddb;
  --charts-indigo-2: #5c47e5;
  --red: #f0532a;
  --red-text: #d04324;
  --amber: #eea933;
  --amber-text: #9e6a10;
  --green: #00ba88;
  --green-text: #088563;
  --primary-light: #ffffff;
  --primary-white: #ffffff;
}

/* pink mode */
html[data-theme="flamingo"] {
  /* :root { */
  --primary-dark: #0b1354;
  --outline-gray: #0b1354;
  --primary-gray: #165baa;
  --primary-blue: #a155b9;
  /* --background-gray: #ffa4b6; */
  --background-gray: #ffc1d1;
  --primary-light: #f9d1d1;
  --primary-white: #f9d1d1;
}

html[data-theme="dark"] {
  --primary-dark: #ffffff;
  --primary-gray: #9a9a9a;
  --outline-gray: #4e4e4e;
  --shape-gray: #9a9a9a;
  --background-gray: #272727;
  --primary-blue: #3662ff;
  --blue-text: #76a5ff;
  --red: #f05b2a;
  --red-text: #f05b2a;
  --amber: #eea933;
  --amber-text: #eea933;
  --green: #00ba88;
  --green-text: #00ba88;
  --primary-light: #1d1d1d;
  --purple: #b95cf1;
}

/* spacing */

:root {
  /* spacing values for smaller screens */
  /* 3, 7, 12, 16, 21, 28, 35, 42, 49, 56, 84  */
  --space-xxxs: 0.222rem;
  --space-xxs: 0.44444444rem;
  --space-xs: 0.6666666rem;
  --space-sm: 0.8888888rem;
  --space-md: 1.3333rem;
  --space-lg: 1.7777rem;
  --space-xl: 2.222rem;
  --space-xxl: 3.1111rem;
  --space-xxxl: 3.5rem;
  --space-xxxxl: 4rem;
  --space-xxxxxl: 5.333rem;
  --space-xxxxxxl: 7.111rem;
  --space-xxxxxxxl: 10rem;
}

@media only screen and (min-width: 1200px) {
  :root {
    /* spacing values for larger screens */
    /* 4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 96 */
    --space-xxxs: 0.222rem;
    --space-xxs: 0.44444444rem;
    --space-xs: 0.6666666rem;
    --space-sm: 0.8888888rem; /* 1rem; */
    --space-md: 1.3333rem; /* 1.5rem; */
    --space-lg: 1.7777rem; /* 2rem; */
    --space-xl: 2.222rem; /* 2.5rem; */
    --space-xxl: 3.1111rem; /* 3rem; */
    --space-xxxl: 3.5rem;
    --space-xxxxl: 4rem;
    --space-xxxxxl: 5.333rem; /* 6rem; */
    --space-xxxxxxl: 7.111rem; /* 8rem; */
    --space-xxxxxxxl: 10rem;
  }
}

/* typography */

:root {
  --font-primary: "Inter", sans-serif;
  --font-secondary: "WhyteInktrap", "Helvetica Neue", sans-serif;

  /* set base values */
  --text-base-size: 1rem;
  --line-height: 1.35;

  /* type scale */
  --text-xxs: 0.625rem; /* caps */
  --text-xs: 0.625rem; /* small text */
  --text-sm: 0.75rem; /* sub text */
  --text-md: 1rem; /* body text */
  --text-lg: 1.25rem; /* h5 */
  --text-xl: 1.563rem; /* h4 */
  --text-xxl: 1.938rem; /* h3 */
  --text-xxxl: 2.375rem; /* h2 */
  --text-xxxxl: 3rem; /* h1 */
  font-size: var(--text-base-size);
}

/*
  On larger desktop screens use 18px as a base font-size
*/
@media only screen and (min-width: 1400px) {
  :root {
    --text-base-size: 1.125rem;

    /* type scale */
    --text-xxs: 0.561rem; /* caps */
    --text-xs: 0.666rem; /* small text */
    --text-sm: 0.777rem; /* sub text */
    --text-md: 1rem; /* body text */
    --text-lg: 1.278rem; /* h5 */
    --text-xl: 1.55rem; /* h4 */
    --text-xxl: 1.94rem; /* h3 */
    --text-xxxl: 2.44rem; /* h2 */
    --text-xxxxl: 3.05rem; /* h1 */
  }
}

/* line-height: 1.35; */
/* line-height: 1.1; */

/* layout */

:root {
  --sidebar-width: 4rem;
}
