.renderer {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}

.tissueRenderer,
.targetRenderer,
.componentRenderer,
.noResultsRenderer,
.groupRenderer,
.pathwayRenderer,
.missingSuggestion,
.diseaseSuggestion,
.defaultRenderer {
  composes: renderer;
}

.renderer > * {
  padding: var(--space-sm) var(--space-xs);

  /* make sure the height of the row is stable across cells with different font size*/
  line-height: 0.9rem;
}

.renderer > *:not(:last-child) {
  border-right: 1px solid var(--outline-gray);
}

.cellLine,
.compartment,
.value,
.compound,
.type {
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  flex: 0 0 10%;
  text-align: center;
  white-space: nowrap;
}

.unit {
  composes: text--xxs from "@syntensor/common/styles/typography.module.css";
  flex: 0 0 125px;
  text-align: right;
  color: var(--primary-gray);
}

.compartment {
  flex: 1 0 50px;
}

.value {
  flex: 0 0 60px;
}

.type {
  flex: 1 1 var(--space-xxxxxxl);
  text-align: right;
}

.name {
  flex: 0 0 85%;
  white-space: pre-wrap;
}

.pathwayId {
  flex: 0 0 150px;
}

.name span {
  color: var(--blue-text);
}

.showAllRenderer {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: var(--space-sm);
  border-top: 1px solid var(--primary-dark);
  margin-top: -1px;
}

.pathwayRenderer .renderCol:last-child {
  flex-grow: 1;
}

.groupLabel span {
  color: var(--blue-text);
}

.synonyms {
  composes: name;
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  font-style: italic;
}

.missingSuggestion .renderCol:last-child {
  flex-basis: 100%;
}

.componentRenderer .synonyms,
.componentRenderer:hover .synonyms {
  color: var(--primary-gray) !important;
}
