.login {
  composes: profile from "./profile.module.css";
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  composes: contentContainer from "@syntensor/common/styles/layouts.module.css";
  display: flex;
  justify-content: space-between;
}

.headerContainer {
  composes: container;
}

.contentContainer {
  composes: container;
  align-items: center;
}

.contentContainer > *:first-child {
  flex-grow: 1;
  padding-right: var(--space-xxxxxl);
}

.footerContainer {
  composes: container;
  justify-content: center;
}

.header,
.footer {
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  padding: var(--space-xl) 0;
}

.header {
  justify-content: space-between;
}

.footer {
  border-top: 1px solid var(--primary-gray);
  color: var(--primary-gray);
}

.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--space-xl) 0;
}

.headerTitle {
  composes: headerTitle from "./profile.module.css";
}

.headerAccessLink {
  text-transform: uppercase;
  color: var(--primary-gray);
  text-decoration: none;
}
.headerAccessLink:hover {
  text-decoration: underline;
}

.title {
  composes: title from "./profile.module.css";
}

.form {
  composes: form from "./profile.module.css";
}

.row {
  composes: row from "./profile.module.css";
}

.logo,
.title {
  margin-bottom: var(--space-md);
}

.buttonRow {
  composes: buttonRow;
}

.link {
  color: var(--primary-gray);
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}

.logo {
  composes: logo from "./profile.module.css";
}

.protein {
  width: 19.5rem;
  height: 37.5rem;
  flex-shrink: 1;
  background-image: url("./protein_light@2x.png");
  background-size: cover;
}
