.componentDetail {
}

.title {
}

.headerTitles {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-lg);
}

.headerTitleWrapper {
  display: flex;
  align-items: center;
  margin: 0 0 var(--space-md);
}
.headerTitleWrapper > *:not(:last-child) {
  margin-right: var(--space-xs);

  /* for rank tags */
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerTitle {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  font-weight: 600;
  font-family: var(--font-primary);
  max-width: 75vw;
}

.description {
  display: flex;
  align-items: center;
  color: var(--primary-gray);
}

.description > *:not(:last-child) {
  margin-right: var(--space-xs);
  padding-right: var(--space-xs);
  border-right: 1px solid var(--outline-gray);
}

.descriptionValue {
  color: var(--primary-dark);
}

.headerList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  list-style-type: none;
}

.headerList li {
  margin: var(--space-xxxs) 0;
}

.headerLink {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  display: flex;
  align-items: center;
  color: var(--blue-text);
  text-decoration: none;
}
.headerLink:hover {
  text-decoration: underline;
}

.headerLink path {
  fill: var(--blue-text);
}

.compoundTableHeaderCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableValue {
  display: block;
  width: calc(100% + 2 * var(--space-xs));
  min-height: 100%;
  height: 2.25rem; /* make sure even absolute positioned protein cells have correct height */
  margin: calc(-1 * var(--space-xs));
  padding: var(--space-xs);
}

.tableTitle {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-xs);
  font-family: var(--font-primary);
}

.components {
  margin: var(--space-sm) 0;
}

.componentsTitles {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
  margin: var(--space-md) var(--space-sm) 0;
}
