.compounds {
  composes: text--xxs from "@syntensor/common/styles/typography.module.css";
  display: flex;
  cursor: pointer;
}

.subnode {
}

.name {
  position: absolute;
  top: 15px;
  left: 0;
  width: 12rem;
  text-align: right;
  filter: drop-shadow(0px 0px 1px var(--primary-light));
  transform: translate(-100%, -50%);
}
.subnode .name {
  top: 10px;
  left: var(--space-xs);
  font-size: 8px;
}

.interactionPlaceholder {
  display: block;
  width: 50px;
  height: 50px;
  margin-top: -10px;
}
.subnode .interactionPlaceholder {
  width: 40px;
  height: 40px;
}

.list {
  display: flex;
  height: 28px;
  list-style-type: none;
  border: 1px solid var(--outline-gray);
}

.compound {
  position: relative;
  height: 100%;
}

.compoundItem:not(:last-child) {
  border-right: 1px solid var(--outline-gray);
}

.box {
  display: block;
  height: 100%;
  padding: var(--space-xxs);
  cursor: pointer;
  transition: 0.2s opacity;
}

.deselectedBox {
  opacity: 0.25;
}

.selectedBox {
  opacity: 1;
}

.value {
  color: var(--global-dark);
}

.darkValue {
  color: var(--global-light);
}

.label {
  position: absolute;
  top: 0;
  transform: translate(var(--space-md), calc(-1 * var(--space-sm)))
    rotate(-45deg);
  transform-origin: top left;
  white-space: nowrap;

  display: none;
}

.compound:hover .label {
  display: block;
}
