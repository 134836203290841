.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.componentWrapper,
.labelWrapper {
  position: absolute;
  top: 0;
  left: 0;
}
.componentWrapper:hover {
  z-index: 10;
}

.componentWrapper {
  /* hidden by default until positioned, and appeared, in JS */
  visibility: hidden;
}

.component {
  /* positioning to the left */
  /* transform: translate(var(--space-md), -50%); */
}

.componentLabel {
  composes: text--xxs from "@syntensor/common/styles/typography.module.css";
}
