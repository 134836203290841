.label {
  display: flex;
  flex-direction: column;
}

.inputLabel {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  color: var(--primary-gray);
}

.label .inputLabel {
  margin-bottom: var(--space-xs);
}
