.legend {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  max-width: 25rem;
  min-width: 10rem;
  background-color: var(--primary-light);
  border: 1px solid var(--outline-gray);
  cursor: default;
}

.header {
  position: relative;
}

.collapseBtn {
  width: 100%;
  padding: var(--space-xs);
  text-align: left;
  background-color: transparent;
  appearance: none;
  border: 0;
  cursor: pointer;
}

.collapseIcon {
  position: absolute;
  top: var(--space-xs);
  right: var(--space-sm);
  pointer-events: none;
}
.collapseIcon * path {
  fill: var(--primary-dark);
}

.content {
  padding: var(--space-sm) var(--space-sm) var(--space-sm);
  border-top: 1px solid var(--outline-gray);
}

.section {
  padding-bottom: var(--space-sm);
}

.section:not(:last-child) {
  margin-bottom: var(--space-sm);
  border-bottom: 1px solid var(--outline-gray);
}

.sectionTitle {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  max-width: 22rem;
  padding-bottom: var(--space-sm);
  font-family: var(--font-primary);
  padding-right: var(--space-sm);
  color: var(--primary-gray);
}

.sectionContent {
  display: flex;
  flex-wrap: wrap;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-xs);
}

.node {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  width: 2rem;
  text-align: center;
  border-radius: 50%;
  height: 2rem;
  font-size: 0.75rem;
  color: var(--primary-gray);
  border: 1px solid var(--primary-gray);
}

.complexNode {
  border-radius: 10px;
}

.compartmentNode {
  border: 1px dashed var(--primary-gray);
  font-size: 0;
  border-radius: 0;
}

.label {
  padding: 0 var(--space-xs) 0 var(--space-xxs);
}

.edge {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-xxs);
}

.edgeGraphic,
.edgeGraphicLine {
  width: 35px;
}

.edgeGraphic {
  position: relative;
  transform: rotate(-25deg);
}
.edgeGraphicLine {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background-color: var(--primary-gray);
}
.edgeGraphicSymbol {
  position: absolute;
  top: 0;
  right: 0;
  height: 10px;
  width: 10px;
  transform: translate(2px, -5px);
  background-color: var(--primary-gray);
}

.circleGraphicSymbol {
  border-radius: 50%;
}

.teeGraphicSymbol {
  height: 11px;
  width: 3px;
}

.veeGraphicSymbol {
  clip-path: polygon(100% 50%, 0 100%, 50% 50%, 0 0);
}
