.editableTextField {
  position: relative;
}

.hasError .textArea {
  border-color: var(--red);
}

.editableTextField .value {
  margin-right: var(--space-md);
}

.btns {
  position: absolute;
  top: 6px;
  right: 0;
}

.btn {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.btn:not(:last-child) {
  margin-right: calc(-1 * var(--space-xxs));
}

.btn path {
  fill: var(--primary-gray);
}

.btn:hover:not(:disabled) path {
  fill: var(--primary-dark);
}

.editBtn {
  composes: btn;
}

.submitBtn {
  composes: btn;
  fill: var(--primary-dark);
}

.cancelBtn {
  composes: btn;
  opacity: 0.33;
}

.textArea {
  width: 100%;
  padding: var(--space-xxs) var(--space-xl) var(--space-xxs) var(--space-xxs);
  border: 1px solid var(--outline-gray);
  background-color: transparent;
}

.loader {
  position: absolute;
  top: 12px;
  right: 12px;
}
