.landing {
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 62rem;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0 var(--space-sm);
  color: var(--primary-gray);
}

.content p {
}

.content p strong {
  color: var(--primary-dark);
}

.body {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.title,
.subtitle,
.sectionTitle {
  font-family: var(--font-primary);
  color: var(--primary-dark);
}

.title {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  margin: var(--space-xxl) 0;
}

.subtitle {
  composes: text--xxxl from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-secondary);
  margin-bottom: var(--space-md);
}

.sectionTitle {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  display: flex;
  align-items: center;
  margin-bottom: var(--space-md);
}

.logo {
  width: var(--space-xxxxxl);
  height: var(--space-xxxxl);
  margin-bottom: var(--space-sm);
}

.cols {
  display: flex;
  margin: var(--space-xl) calc(-1 * var(--space-md));
}
.cols > * {
  flex: 1 0 33%;
}

.icon {
  margin-right: var(--space-xs);
}
.icon path {
  fill: var(--primary-dark);
}

.typeBadge {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  composes: icon;
  border-radius: 50%;
  color: var(--global-light);
  display: inline-block;
  height: 2.2rem;
  line-height: 1rem;
  padding: 0.6rem;
  text-transform: uppercase;
  width: 2.2rem;
  background-color: var(--primary-dark);
}

.section {
  padding: 0 var(--space-md);
}

.lastUpdated {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-md);
}
.lastUpdated > span {
  font-size: var(--text-sm);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: var(--space-xxl);
  padding: var(--space-xxl) 0;
  border-top: 1px solid var(--outline-gray);
}

.footer > * {
  flex: 1 0 50%;
}

.copyright {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  color: var(--primary-dark);
}

.privacyFooter {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.privacyFooter > footer {
  width: 100%;
}

.accessBtn {
  margin-top: var(--space-md);
  width: 13.5rem;
}
