.logo {
  width: 100%;
  height: 100%;
  background-image: url("./logo_light.png");
  background-size: cover;
}

html[data-theme="dark"] .logo {
  background-image: url("./logo_dark.png");
}
