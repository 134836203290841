.comments {
  width: 30rem;
  height: 100%;
  overflow: auto;
  border-top: 1px solid var(--outline-gray);
  border-left: 1px solid var(--outline-gray);
  background-color: var(--primary-light);

  /* similar to fixed columns of the data grid */
  box-shadow: -2px 0 5px -2px hsla(0, 0%, 53%, 0.3);
}
html[data-theme="dark"] {
  .comments {
    box-shadow: -2px 0 5px -2px hsla(100, 100%, 100%, 0.3);
  }
}

.fixedHeader {
  position: fixed;
  width: 100%;
  background-color: var(--primary-light);
  z-index: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-md);
}

.error {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  padding: var(--space-sm);
  color: var(--red);
}

.content {
  padding-top: 9.65rem;
}

.title {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  height: 1.375rem;
  font-family: var(--font-primary);
}

.btns {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  display: flex;
  justify-content: space-between;
}
.btns > * {
  flex: 1 0 50%;
}

.btn {
  width: 100%;
  padding: var(--space-sm);
  border: 0;
  background-color: transparent;
  border-top: 1px solid var(--outline-gray);
  border-bottom: 1px solid var(--outline-gray);
  cursor: pointer;
  color: var(--primary-gray);
}
.btn:hover {
  color: var(--primary-dark);
}

.btnActive {
  color: var(--primary-dark);
  border-bottom: 1px solid var(--primary-dark);
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list > li {
  border-bottom: 1px solid var(--outline-gray);
}

.search {
  display: flex;
  position: relative;
  border-bottom: 1px solid var(--outline-gray);
}
.search > * {
  flex: 1 0 auto;
}

.searchInput {
  position: relative;
}

.search input {
  /* do not double border */
  border: 0;
  outline: 0;
  border-right: 1px solid var(--outline-gray);
  background-color: var(--background-gray);
}

.search input:focus {
  border: 1px solid var(--primary-blue);
}

.search > *:last-child {
  flex: 0 0 7.5rem;
}

.search select {
  border: 0;
}

.searchClear {
  position: absolute;
  top: var(--space-sm);
  right: var(--space-sm);
  z-index: 1;
}

.searchClearBtn {
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

.searchClearBtn path {
  fill: var(--outline-gray);
}

.emptyComments {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  padding: var(--space-md) var(--space-sm);
  border-bottom: 1px solid var(--outline-gray);
}

.repliesList {
  list-style-type: none;
}

.replyForm {
  border-left: var(--space-sm) solid var(--background-gray);
  padding: var(--space-sm);
}

.form {
  display: flex;
  width: 100%;
  margin-bottom: -1px; /* hide double border */
}

.form input {
  border-left: 0;
  border-right: 0;
  padding: var(--space-sm);
}

.form > * {
  flex: 1 1 auto;
}

.replyBtn {
  flex-basis: 8rem;
  background-color: transparent;
  border: 1px solid var(--outline-gray);
}

.addComments {
  border-bottom: 1px solid var(--outline-gray);
}
