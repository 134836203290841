.mobOnly {
  display: block;
}
@media screen and (min-width: 900px) {
  .mobOnly {
    display: none;
  }
}

.desktopOnly {
  display: none;
}
@media screen and (min-width: 900px) {
  .desktopOnly {
    display: block;
  }
}

.contentContainer {
  max-width: 50rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.wideContentContainer {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.sticky {
  position: sticky;
}
