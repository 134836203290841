.searchFormFilters {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  display: flex;
  white-space: nowrap;
  list-style-type: none;
}

.searchFormFilters > li {
  margin-right: -1px;
}

.activeButton {
  color: var(--primary-light) !important;
}
.activeButton::before {
  background-color: var(--primary-dark) !important;
}
