.table {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  width: 100%;
  border-collapse: collapse;
  color: var(--primary-dark);
}

.td,
.th {
  padding: var(--space-xs);
  text-align: center;
  white-space: nowrap;
  border: 1px solid var(--outline-gray);
}

.th {
  font-weight: 500;
}

.td {
  letter-spacing: -0.02rem;
  text-transform: uppercase;
  background-color: var(--background-gray);
}

.td:first-child,
.th:first-child {
  text-align: left;
}

.description {
  composes: text--xxs from "@syntensor/common/styles/typography.module.css";
  letter-spacing: -0.02rem;
  color: var(--primary-gray);
}

.link {
  color: var(--blue-text);
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}
