.filters {
  display: flex;
  margin-bottom: calc(-1 * var(--space-xs));
}
.filters > * {
  margin-bottom: var(--space-xs);
}
.filters > *:not(:last-child) {
  margin-right: var(--space-sm);
}

.filter {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  position: relative;
  /* z-index: 2; */
}

.filterHeader {
  position: relative;
  display: flex;
  padding: var(--space-xxs) var(--space-lg) var(--space-xxs) var(--space-xxs);
  background-color: var(--primary-light);
  border: 1px solid var(--outline-gray);
}

.filterHeaderBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
}
.filterHeaderBtn:hover {
  color: var(--primary-gray);
}

.filterIcon {
  position: relative;
  top: 1px;
  margin-right: var(--space-xxxs);
  margin-left: calc(-1 * var(--space-xxxs));
}
.filterIcon path {
  fill: var(--primary-dark);
}
.filterHeaderBtn:hover .filterIcon path {
  fill: var(--primary-gray);
}

.filterValue {
  margin-left: var(--space-xxs);
  color: var(--primary-blue);
}

.filterDropdown {
  position: absolute;
  z-index: 2;
  top: 100%;
  margin-top: -1px;
  min-width: 100%;
}

.filterDropdownContainer {
  padding: var(--space-sm);
  background-color: var(--primary-light);
  border: 1px solid var(--outline-gray);
  border-top: 0;
}

.filterList {
  list-style-type: none;
  margin: 0;
}

.filterListItem {
  margin-bottom: var(--space-xxxs);
}

.filterListLabel {
  cursor: pointer;
}

.filterListLabel input {
  margin-right: var(--space-xxs);
}

.filterCloseBtn {
  position: absolute;
  top: 50%;
  right: 5px;
  padding: var(--space-sm);
  width: 1rem;
  transform: translate(0, -50%);
}
.filterCloseBtn path {
  fill: var(--primary-dark);
}
.filterCloseBtn::before {
  background-color: transparent;
}

.multiselectFilter {
  composes: filterDropdownContainer;
}

.multiselectFilterWide {
  min-width: 500px;
}
.multiselectFilterWide .filterList {
  display: flex;
  flex-wrap: wrap;
}

.multiselectFilterWide .filterListItem {
  margin-right: var(--space-xs);
}

.numericFilter {
  composes: filterDropdownContainer;
}

.filterBtns {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-sm);
}
.filterBtns > *:not(:last-child) {
  margin-right: var(--space-xs);
}

.filterBtns button {
  width: auto;
  padding: var(--space-xxs) var(--space-md);
}

.filterBtns path {
  fill: var(--primary-dark);
}

.instructions {
  display: block;
  margin-bottom: var(--space-xs);
}

.inputError {
  outline: 1px solid var(--red);
}
