.input {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  width: 100%;
  height: 100%;
  padding: var(--space-xs) var(--space-sm);
  border-radius: 0;
  border: 1px solid var(--primary-dark);
  appearance: none;
}

.input:read-only:not([type="radio"]) {
  opacity: 0.5;
}

.input[type="radio"] {
  width: var(--space-md);
  height: var(--space-md);
  margin-right: var(--space-xs);
  padding: 0;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
}

.input[type="radio"]:checked {
  border: 6px solid var(--primary-blue);
  background-color: var(--background-gray);
}

.input[type="radio"]:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.extraSmall {
  /* padding: var(--space-xxs) var(--space-xs); */
}
