.metrics {
}

.content {
  margin: var(--space-sm) auto;
  padding: 0 var(--space-sm);
  padding-top: 4rem; /* compensate for the fixed header */
  max-width: 76.2rem; /* 1340px on large screen (for 18px = 1rem) */
  width: 100%;
}

.content ul {
  margin: var(--space-sm);
}

.content .metricName {
  margin-right: var(--space-sm);
  color: var(--primary-gray);
}

.highlightCell {
  color: var(--primary-blue);
}

.title {
  composes: text--xxl from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-secondary);
  padding: var(--space-xl) 0 var(--space-xxl);
  border-bottom: 1px solid var(--outline-gray);
}

.titleWithBtn {
  composes: title;
  display: flex;
}
.titleWithBtn > *:first-child {
  margin-right: var(--space-sm);
}

.section:not(:last-child) {
  padding-bottom: var(--space-xxl);
  border-bottom: 1px solid var(--outline-gray);
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.sectionTitle {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  margin: var(--space-sm) 0;
  color: var(--primary-dark);
}

.sectionParagraph,
.sectionSubtext {
  margin: var(--space-xs) 0;
  color: var(--primary-gray);
}

.sectionParagraph strong {
  color: var(--primary-dark);
}

.sectionLargeParagraph {
  margin: var(--space-xs) 0;
  color: var(--primary-gray);
}

.sectionSubtext {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
}

.sectionControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionImage {
  width: 100%;
}

.sectionImageCentered {
  composes: sectionImage;
  max-width: 60%;
  margin: 0 auto;
  text-align: center;
}

.sectionImageCentered img {
  width: 100%;
}

.sectionSubtitle {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-sm);
}

.sectionExplainer {
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  margin: var(--space-lg) 0 var(--space-sm);
  color: var(--primary-gray);
}

.container {
  text-align: right;
}

.cols {
  composes: grid from "@syntensor/common/styles/grid.module.css";
  grid-column-gap: var(--grid-column-gap);
  padding: 0;
}

.col6 {
  grid-column: 12 span;
}
@media screen and (min-width: 1024px) {
  .col6 {
    grid-column: 6 span;
  }
}

.col12 {
  grid-column: 12 span;
}

.chartTitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-sm);
  color: var(--primary-dark);
}

.chartImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--space-sm);
}

.imageTitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  margin: var(--space-md) 0 var(--space-sm);
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row > * {
  flex-grow: 0;
}

.rowWithBorder {
  margin-top: var(--space-xxl);
  padding-top: var(--space-lg);
  border-top: 1px solid var(--outline-gray);
}

/* for explicit styling (e.g. tables) */
.tableLink {
  composes: link from "@syntensor/common/components/table/table.module.css";
}

.label {
  transform: rotate(270deg) translate(-50%, 0);
  white-space: nowrap;
  position: absolute;
  transform-origin: top left;
  bottom: 0;
  left: 0;
}

.img {
  margin: 0 auto;
}
