.app {
  display: flex;
  width: 100%;
  /* height: 100%; */
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 var(--sidebar-width);
  width: var(--sidebar-width);
  height: 100vh;
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 2;
  background-color: var(--primary-light);
  border-right: 1px solid var(--outline-gray);
}

.content {
  /* min-height: 100vh; */
  overflow: auto;

  flex: 1 1 auto;
  
  /* set as a parent for the error and loading overlays */
  position: relative;
}
