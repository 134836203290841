.darkModeToggle {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 var(--space-md);
  background: transparent;
  border: 0;
  cursor: pointer;
}
@media screen and (max-width: 899px) {
  .darkModeToggle {
    padding: var(--space-md);
    padding-left: 0;
  }
}

.graphic {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transition: 0.25s opacity;
}

.graphic path {
  fill: rgba(0, 0, 0, 0.25);
}

.darkModeToggle:hover .graphic path {
  fill: var(--primary-dark);
}

.darkThemeGraphic {
}

html[data-theme="dark"] .graphic path,
.darkThemeGraphic path {
  fill: rgba(255, 255, 255, 0.5);
}
