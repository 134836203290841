.newUserForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-top: var(--space-xxs) solid var(--outline-gray);
}

.header {
  display: flex;
  padding: 1.1rem var(--space-sm) 1.1rem;
  border-bottom: 1px solid var(--outline-gray);
}

.backBtn {
  margin-right: var(--space-xxs);
}

.title,
.subtitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  height: 1.35rem;
  line-height: 1.35rem;
  font-family: var(--font-primary);
}

.title {
  color: var(--primary-gray);
}

.title {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  height: 1.35rem;
  line-height: 1.35rem;
  font-family: var(--font-primary);
}

.content {
  composes: contentContainer from "@syntensor/common/styles/layouts.module.css";
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--space-lg) 0;
}

.formSection {
  margin-bottom: var(--space-lg);
}

.formFooter {
  display: flex;
  justify-content: flex-end;
}

.formTitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
  margin-bottom: var(--space-xs);
}

.formDescription {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  color: var(--primary-gray);
  margin: var(--space-xs) 0 var(--space-xs);
}

.btn {
  display: flex;
}

.btn > * {
  position: relative;
  flex-basis: 33%;
}

.error {
  margin-top: var(--space-md);
  color: var(--red-text);
}

.success {
  margin-top: var(--space-md);
  color: var(--green-text);
}

.formSection input,
.formSection textarea {
  padding: var(--space-sm) var(--space-xl) var(--space-sm) var(--space-md);
  background-color: var(--primary-light);
  color: var(--primary-dark);
  border: 1px solid var(--outline-gray);
}

.formHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-sm);
}

.formHeader .formTitle {
  margin-bottom: 0;
}

.formHeader {
  display: flex;
}

.backBtn path {
  fill: var(--primary-gray);
}

.backBtn:hover path {
  fill: var(--primary-dark);
}

.note {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  color: var(--primary-gray);
}

.componentTypes {
  display: flex;
  flex-wrap: wrap;
}

.componentTypes > div {
  margin-right: var(--space-sm);
}
