.legend {
  display: flex;
  flex-direction: row;
}

.title {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  padding-left: var(--space-md);
}

.subtitle {
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  max-width: 22rem;
  text-align: right;
  padding-right: var(--space-sm);
  color: var(--primary-gray);
}

.scaleWrapper {
  width: 22rem;
}

.scale {
  display: flex;
  flex-direction: column;
}

.scaleGraphics {
  margin-bottom: var(--space-xxs);
}

.scaleGraphic {
  width: 100%;
  height: 1rem;
  position: relative;
}

.scaleGraphic:not(:last-child) {
  margin-bottom: 0.222rem;
}

.scaleGraphicBar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.scaleGraphicLabels,
.scaleLabels {
  text-transform: uppercase;
}

.scaleGraphicLabels {
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 var(--space-xxs);
  color: var(--primary-white);
}

.scaleLabels {
  composes: text--xxs from "@syntensor/common/styles/typography.module.css";
  display: flex;
  justify-content: space-between;
}

.geneTranscriptLegend {
  composes: legend;
}

.geneTranscriptLegend .scaleGraphicBar {
  /* background gradient created programatically in js */
}

.geneTranscriptLegend .scaleGraphicLabel:nth-child(2) {
  color: var(--primary-dark);
}

.proteinLegend {
  composes: legend;
}

.proteinLegend .scaleGraphicLabelsDark {
  color: var(--primary-light);
}
