.button {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: var(--space-xs) var(--space-lg);
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  transition: 0.2s background-color, 0.2s color;
  outline: none;

  /*
   btn has pseudo-element with z-index:-1 so make sure
   this is the parent
  */
  z-index: 0;

  /* in case we were rendering anchor */
  text-decoration: none;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: var(--primary-light);
  transition: 0.2s background-color;
}

.button:focus::before,
.button:active::before {
  border: 3px solid var(--outline-gray) !important;
}

.button:not(.disabled):hover {
  color: var(--primary-blue);
}
.button:not(.disabled):hover path {
  fill: var(--primary-blue);
}

.button span + svg {
  margin-left: var(--space-xxs);
}

.button svg + span {
  margin-left: var(--space-xxs);
}

.button.disabled {
  cursor: not-allowed;
  opacity: 0.25;
}

.small {
  composes: text--sm from "../../styles/typography.module.css";
}

/*
  PRIMARY THEME
*/
.primary {
  color: var(--global-light);
}

.primary::before {
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
  opacity: 0.75;
}

.primary svg path {
  fill: var(--global-light);
}

.primary:not(.disabled):hover {
  color: var(--global-light);
}

.primary:not(.disabled):hover::before {
  opacity: 1;
}

.primary:not(.disabled):hover svg path {
  fill: var(--global-light);
}

.primary:active::before,
.primary:focus::before {
  opacity: 1;
}

/*
  SECONDARY THEME
*/
.secondary {
  color: var(--primary-light);
}

.secondary::before {
  background-color: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}

.secondary svg path {
  fill: var(--primary-light);
}

.secondary:not(.disabled):hover {
  color: var(--primary-light);
}

.secondary:not(.disabled):hover::before {
  background-color: var(--primary-gray);
  border: 1px solid var(--primary-gray);
  color: var(--primary-light);
}

.secondary:not(.disabled):hover svg path {
  fill: var(--primary-light);
}

.secondary.disabled::before {
  background-color: var(--shape-gray);
  border: 1px solid var(--shape-gray);
}

/*
  LIGHT THEME
*/
.light::before {
  background-color: var(--primary-light);
  border: 1px solid var(--outline-gray);
}

.light:not(.disabled):hover {
  color: var(--primary-gray);
}
.light:not(.disabled):hover::before {
  border: 1px solid var(--primary-gray);
}

.light:not(.disabled):hover svg path {
  fill: var(--primary-gray);
}

.light.disabled::before {
  border: 1px solid var(--shape-gray);
}

/*
  SUBTLE THEME
  not stricly in the design system but used for view toggles etc.
*/
.subtle::before {
  background-color: var(--primary-light);
  border: 1px solid var(--primary-dark);
}

.subtle:not(.disabled):hover {
  color: var(--primary-dark);
}
.subtle:not(.disabled):hover::before {
  background-color: var(--background-gray);
  border: 1px solid var(--primary-dark);
}

.subtle:not(.disabled):hover svg path {
  fill: var(--primary-gray);
}

.subtle.disabled::before {
  border: 1px solid var(--primary-dark);
}

/*
  TEXT THEME
*/
.text {
  color: var(--primary-blue);
}

.text svg path {
  fill: var(--primary-blue);
}

.text:focus,
.text:active {
  text-decoration: underline;
}

.text:focus::before,
.text:active::before {
  border: 0 !important;
}

/* icons sizing */
.large svg {
  min-width: 1.333rem;
  min-height: 1.333rem;
}

.small svg {
  max-width: 1rem;
  max-height: 1rem;
  min-width: 0.888rem;
  min-height: 0.888rem;
}
