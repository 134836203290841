.trackingPopup {
  max-width: 800px;
  width: 90%;
  max-height: 80vh;
  overflow: auto;
  margin: 0 auto;
  padding: 0;
}

.trackingPopup > div:first-child {
  z-index: 2;
}

.header {
  position: sticky;
  z-index: 1;
  top: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--text-md);
  border-bottom: 1px solid var(--outline-gray);
  background-color: var(--primary-light);
}

@media screen and (min-width: 900px) {
  .header {
    padding: var(--text-xxl);
  }
}

.closeBtn {
  margin-right: calc(-1 * var(--space-lg));
}

.closeBtn path {
  fill: var(--primary-dark);
}

.headerTitle {
  composes: text--xxl from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
  color: var(--primary-gray);
}

.headerTitle strong {
  color: var(--primary-dark);
}

.section {
  padding: var(--space-lg) var(--space-lg);
  color: var(--primary-gray);
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
}

.sectionTitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-sm);
  font-family: var(--font-primary);
  color: var(--primary-dark);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--space-md) 0;
}

.logo svg {
  width: 152px;
  height: 160px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: var(--text-md);
  background-color: var(--primary-light);
  border-top: 1px solid var(--primary-dark);
}

@media screen and (min-width: 900px) {
  .footer {
    position: sticky;
    bottom: 0;
    z-index: 2;
    flex-direction: row;
    padding: var(--text-xxl);
  }
}

.btns {
  display: flex;
  flex-direction: column;
  margin: var(--space-sm) calc(-1 * var(--space-sm));
}
@media screen and (min-width: 900px) {
  .btns {
    flex-direction: row;
    margin: 0 calc(-1 * var(--space-sm));
  }
}

.btns button {
  margin: var(--space-xxs);
  white-space: nowrap;
}
@media screen and (min-width: 900px) {
  .btns button {
    margin: 0 var(--space-xxs);
  }
}

.footer a {
  color: var(--primary-blue);
}

.onLabel {
  color: var(--primary-blue);
}

.offLabel {
  color: var(--primary-gray);
}
