.compoundTableValueCell {
  composes: tableValueCell from "../studies.module.css";
}

.compoundPotencyTableValueCell {
  color: var(--primary-dark);
}

.compoundNameCell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.compoundNameCell circle,
.compoundNameCell rect {
  fill: var(--primary-dark);
}

.compoundNameCell path {
  fill: var(--primary-light);
}

.tooltip {
  margin-left: var(--space-xs);
}
