@import "@syntensor/common/styles/reset.css";
@import "@syntensor/common/styles/vars.css";
@import "@syntensor/common/styles/typography.css";
@import "@syntensor/common/styles/grid.module.css";

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
}

input,
select {
  background-color: var(--primary-light);
}
