.addCommentForm {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  position: relative;
}

.textArea {
  display: block;
  border: var(--space-xxxs) solid transparent;
  width: 100%;
  height: 3.666rem;
  line-height: 1.4;
  padding: var(--space-md) var(--space-sm);
  background-color: var(--background-gray);
  outline: 0;

  /* we are resizing textarea automatically to fit content */
  resize: none;
  overflow: hidden;
}

.textAreaFocused {
  min-height: var(--space-xxxxxxl);
}
.textArea:focus {
  border: var(--space-xxxs) solid var(--primary-blue);
}
.textAreaError {
  border: 1px solid var(--red);
}

.errorMsg {
  color: var(--red);
}

.submitBtn {
  position: absolute;
  right: var(--space-xs);
  bottom: var(--space-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  padding: 0;
  border: 0;
  border-radius: 100%;
  background-color: var(--primary-blue);
  opacity: 0.75;
  cursor: pointer;

  /* hidden by default */
  display: none;
}

.submitBtnVisible {
  display: flex;
}

.submitBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.submitBtn path {
  fill: var(--primary-light);
}

.submitBtn:not(:disabled):hover {
  opacity: 1;
}

.addCommentFormSmall {
}

.addCommentFormSmall .textArea {
  border: 1px solid transparent;
  height: 2.4rem;
  padding: var(--space-xs) var(--space-xs);
}

.addCommentFormSmall .textAreaFocused {
  min-height: var(--space-xxxxl);
}
.addCommentFormSmall .textArea:focus {
  border: 1px solid var(--primary-blue);
}
