.mainNavLink {
  position: relative;
  display: block;
  height: 100%;
  padding: var(--space-md);
  text-decoration: none;
  color: var(--primary-gray);
  text-align: center;
  border: 1px solid var(--outline-gray);
}

.mainNavLink::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: var(--primary-light);
  transition: 0.2s background-color;
}

.mainNavLink:not(.disabled):not(.mainNavLinkActive):hover {
  color: var(--primary-dark);
}
.mainNavLink:not(.disabled):hover::before {
  background-color: var(--background-gray);
}

.mainNavLink:not(.disabled):active::before,
.mainNavLink:not(.disabled):focus::before {
  border: 3px solid var(--outline-gray) !important;
}

.mainNavLinkActive {
  color: var(--global-light);
  background-color: var(--primary-blue);
}

.disabled {
  cursor: not-allowed;
  color: rgba(206,206,206,0.75);
}
html[data-theme="dark"] .disabled {
  color: rgba(206,206,206,0.25);
}