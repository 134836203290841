.avatar {
  display: block;
  padding: var(--space-xs);
  cursor: pointer;
  background-color: var(--primary-light);
}

.image {
  display: block;
  overflow: hidden;
  border-radius: 50%;
  width: var(--space-xl);
  height: var(--space-xl);
  border-radius: 50%;
  border: 2px solid var(--primary-light);
}

.avatar:hover .image {
  border: 2px solid var(--primary-gray);
}

.avatar.isSelected .image {
  border: 2px solid var(--primary-blue);
}