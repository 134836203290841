.header {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  display: flex;
  padding-top: var(--space-xxs);
}

.header::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--space-xxs);
  background-color: var(--background-gray);
  border-bottom: 1px solid var(--outline-gray);
}

.nav {
  flex: 1 0 auto;
}

.list {
  display: flex;
  list-style-type: none;
  margin: -1px;
  padding: 0;
}

.list > * {
  flex: 1 0 16.666%;
  margin-right: -1px;
}

.list > *:first-child > * {
  text-align: left;
}

/* search icon */
.iconItem {
  flex: 0;
  border: 1px solid var(--outline-gray);
}

.iconBtn {
  position: relative;
  height: 100%;
}

.iconBtn path {
  fill: var(--primary-gray);
}
.iconBtn:hover path {
  fill: var(--primary-dark) !important;
}
.iconBtn:hover::before {
  background-color: var(--background-gray);
}

.commentsBtnActive:before {
  background-color: var(--primary-blue) !important;
}

.commentsBtnActive path {
  fill: var(--global-light) !important;
}

.commentsDot {
  display: block;
  position: absolute;
  top: 0.8rem;
  right: 1.5rem;
  width: 0.5rem;
  height: 0.5rem;
  border: 2px solid var(--primary-gray);
  border-radius: 100%;
}

.pageComments {
  background-color: var(--primary-gray);
}

.commentsBtnActive .commentsDot,
.iconBtn:hover .commentsDot {
  border: 2px solid var(--global-light);
}
.commentsBtnActive .pageComments,
.iconBtn:hover .pageComments {
  background-color: var(--global-light);
}
