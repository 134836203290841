.text--xxxxl {
  font-size: var(--text-xxxxl);
}

.text--xxxl {
  font-size: var(--text-xxxl);
}

.text--xxl {
  font-size: var(--text-xxl);
}

.text--xl {
  font-size: var(--text-xl);
}

.text--lg {
  font-size: var(--text-lg);
}

.text--md {
  font-size: var(--text-md);
}

.text--sm {
  font-size: var(--text-sm);
}

.text--xs {
  font-size: var(--text-xs);
  font-weight: 500;
}

.text--xxs {
  font-size: var(--text-xxs);
}

/* reset letter-spacing for anything but default text */
.text--xxxxl,
.text--xxxl,
.text--xxl,
.text--xl,
.text--lg,
.text--sm,
.text--xs,
.text--xxs {
  letter-spacing: 0;
  line-height: 1.2;
}

@media only screen and (min-width: 1400px) {
  .text--xxxxl,
  .text--xxxl,
  .text--xxl,
  .text--xl,
  .text--lg,
  .text--sm,
  .text--xs,
  .text--xxs {
    line-height: 1.15;
  }
}
