.tooltip {
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  pointer-events: none;
  padding: var(--space-xs);
  transform: translate(calc(-100% - 20px), -50%);
  max-width: 12rem;
  text-align: right;
  color: var(--primary-dark);
  background-color: var(--background-gray);
}

.tooltip:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-left-color: var(--background-gray);
  border-width: 10px;
  margin-top: -10px;
  margin-left: -1px;
}
