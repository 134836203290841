.submenu {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  min-width: 12.5rem;
  list-style-type: none;
  border: 1px solid var(--outline-gray);
}

.submenuItem {
}

.submenuItem a {
  display: block;
  padding: var(--space-md) var(--space-xs);
  line-height: 1.333rem;
  color: var(--primary-dark);
  background-color: var(--primary-light);
  border-bottom: 1px solid var(--outline-gray);
  text-decoration: none;
  white-space: nowrap;
}

.submenuItem a:hover {
  color: var(--primary-light);
  background-color: var(--primary-dark);
}

.submenuItemDisabled {
  composes: submenuItem;
  cursor: not-allowed;
}
.submenuItemDisabled > * {
  pointer-events: none;
}
.submenuItemDisabled a {
  color: var(--primary-gray);
}
