.endpointForm {
}

.endpoints {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * var(--space-sm));
}

.formSectionTitle {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  padding: var(--space-sm) 0;
}

.endpointFormSection {
  composes: formSection from "../create.module.css";
}

.endpointFormTitle {
  composes: formTitle from "../create.module.css";
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
}

.endpoints > div {
  flex: 0 0 50%;
  margin-bottom: var(--space-sm);
}

.endpoint {
  margin-right: var(--space-sm);
  padding: var(--space-sm);
  background: var(--background-gray);
}

.endpointEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  height: 100%;
  margin-right: var(--space-sm);
  border: 1px dashed var(--outline-gray);
}

.endpoints input,
.endpoints select,
.endpoints textarea {
  color: var(--primary-light) !important;
  background-color: var(--primary-gray) !important;
  padding: var(--space-sm) !important;
  font-size: 0.666rem;
}

.endpoints textarea {
  resize: vertical;
}

.endpointForm {
  position: relative;
}

.endpointFormRemoveBtn {
  position: absolute;
  top: -0.2rem;
  right: -0.5rem;
  width: 2rem;
  appearance: none;
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.endpointFormRemoveBtn:hover {
  opacity: 1;
}
.endpointFormRemoveBtn path {
  fill: var(--primary-dark);
}

.addEndpointBtn {
  width: 10rem;
}
