.placeholder {
  height: 100%;
  border-top: var(--space-xxs) solid var(--outline-gray);
}

.content {
  composes: contentContainer from "@syntensor/common/styles/layouts.module.css";
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -9.666rem;
}

.title {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  margin: var(--space-xxxxl) 0 ;
  font-family: var(--primary-font);
}

.logo {
  width: var(--space-xxxxxl);
  height: var(--space-xxxxl);
  margin-bottom: var(--space-sm);
}

.subtitle {
  composes: text--xxl from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-sm);
}

.description {
  margin-bottom: var(--space-lg);
  color: var(--primary-gray);
}

.form {
  display: flex;
}

.form > * {
  flex: 1 1 auto;
  margin-right: var(--space-sm);
}

.form > *:last-child {
  margin-right: 0;
  flex-grow: 0;
  flex-basis: var(--space-xxxxxxl);
}

.diseaseInput {
  position: relative;
}

.diseaseInput input {
  padding-left: var(--space-xxl);
}

.searchIcon {
  position: absolute;
  top: 0.75rem;
  left: 1rem;
}
