.boxPlot {
  position: relative;
  width: 100%;
  height: 100%;
}

.whiskers,
.box {
  display: block;
  position: absolute;
  top: 0;
}

.whiskers {
  height: 100%;
}

.whiskers::before,
.whiskers::after {
  content: "";
  display: block;
  position: absolute;
  height: 70%;
  width: 1px;
  top: 15%;
  background-color: var(--color);
}
.whiskers::before {
  left: 0;
}
.whiskers::after {
  right: 0;
}

.whiskers span {
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  top: 50%;
  background-color: inherit;
}

.box {
  height: 100%;
}

.circle {
  display: block;
  position: absolute;
  top: 50%;
  width: 8px;
  height: 8px;
  background-color: var(--primary-white);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.circle,
.whiskers,
.box {
  transition: 0.3s width, 0.3s left;
}
