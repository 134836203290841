.header {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  display: flex;
  padding-top: var(--space-xxs);
  border-bottom: 1px solid var(--outline-gray);
}

.header::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--space-xxs);
  background-color: var(--background-gray);
  border-bottom: 1px solid var(--outline-gray);
}
/* 
.header {
  composes: header from "../target_app/header/header.module.css";
  border-bottom: 1px solid var(--outline-gray);
} */

.header > div {
  padding: 1.1rem var(--space-sm);
}

.headerTitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  height: 1.35rem;
  color: var(--primary-gray);
  font-family: var(--font-primary);
}

.headerTitle strong {
  color: var(--primary-dark);
  font-weight: 500;
}

.headerSubtitle {
  margin-right: var(--space-xxxs);
  color: var(--primary-gray);
}

.backLink {
  display: flex;
}

.search {
  display: flex;
  border-bottom: 1px solid var(--outline-gray);
}
.search > * {
  margin-bottom: -1px; /* compensate for border */
  padding: var(--space-sm);
}
.search > :not(:first-child) {
  border-left: 1px solid var(--outline-gray);
}
.search > :first-child {
  flex: 1 0 auto;
}

.list {
  composes: oneColGrid from "@syntensor/common/styles/grid.module.css";
  margin: var(--space-sm) 0;
  padding: 0 var(--space-sm);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--space-sm);
  grid-row-gap: var(--space-sm);
  flex-wrap: wrap;
  grid-auto-rows: 1fr;
}
.grid > * {
  flex: 0 1 50%;
}

.project {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--space-sm) var(--space-md);
  text-decoration: none;
  border: 1px solid var(--outline-gray);
  background-color: var(--background-gray);
}

.group,
.name,
.description {
  margin-bottom: var(--space-xxs);
}

.group {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
}

.name {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
  color: var(--blue-text);
  font-weight: 600;
}

.description {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  color: var(--primary-gray);
}
