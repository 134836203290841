.header {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  position: fixed;
  z-index: 10;
  left: var(--sidebar-width);
  right: 0;
  top: 0;
  display: flex;
  border-top: var(--space-xxs) solid var(--outline-gray);
  border-bottom: 1px solid var(--outline-gray);
}

.nav {
  flex: 1 0 auto;
}

.list {
  display: flex;
  list-style-type: none;
  margin: -1px;
  padding: 0;
}

.list > * {
  flex: 1 0 auto;
  margin-right: -1px;
}

.list > *:first-child > * {
  text-align: left;
}

.list > *:last-child {
  margin-right: 0;
}

.sidebarBtn > * {
  height: 100%;
  padding: 0.8rem 1.05rem;
  display: flex;
  align-items: center;
}

.diseaseTitle {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
}

.compoundTitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  color: var(--primary-dark);
  font-family: var(--font-primary);
}
