.searchInput {
  /* make sure we have proper height */
  display: block;
  position: relative;

  /* in case there was a filter popup */
  z-index: 1;
}

.icon,
.preloader {
  position: absolute;
  top: 0.9rem;
  left: 1rem;
}

.preloader span {
  border-color: var(--primary-dark) !important;
}

.input {
  width: 100%;
  padding: var(--space-sm) var(--space-md) var(--space-sm) var(--space-xxl);
  border: 1px solid var(--outline-gray);
}

.inputWithLeftOffset {
  padding-left: 7.5rem;
}

.clearBtn {
  position: absolute;
  top: 0;
  right: 0;
}
.clearBtn button {
  padding: var(--space-xs);

  /* matches with the parent input so background would cover input border */
  background-color: transparent;
}

/* small theme */
.small .input {
  /* compensate for borders */
  padding: calc(var(--space-xs) - 1px) var(--space-sm)
    calc(var(--space-xs) - 1px) var(--space-xl);
  font-size: var(--text-sm);
}

.small .icon,
.small .preloader {
  top: 0.6rem;
  left: 1rem;
}

.small .inputWithLeftOffset {
  padding-left: 7.5rem !important;
}
