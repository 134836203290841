.search {
  padding: var(--space-xxs) var(--space-sm);
  background-color: var(--primary-light);
}

.searchWithTerm input[type="text"] {
  outline: none;
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px var(--primary-blue);
}

.searchWithError input[type="text"] {
  outline: none;
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px var(--red);
}

.search input[type="text"]:focus {
  outline: none;
  border: 0;
  box-shadow: 0 0 0 var(--space-xxxs) var(--primary-blue);
  border: 1px solid transparent;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: var(--space-sm) 0 0;
}

/*
  Overlay only page content, while keeping search bar accessible
*/
.overlay {
  left: 4rem;
  z-index: 1;
}
