.dataGrid {
  max-height: 100vh;
}

.dataGrid :global(.rdg) {
  height: inherit;
  border: 0;
  border-top: 4px solid var(--outline-gray);
  /* border-left: 1px solid var(--outline-gray); */
  /* border-right: 1px solid var(--outline-gray); */

  background-color: var(--primary-light);
}

.dataGrid :global(.rdg-header-row) {
  background-color: var(--primary-light);
}

/* .dataGrid :global(.rdg-header-row) :global(.rdg-cell):first-child { */
/*   border-left: 1px solid var(--outline-gray); */
/* } */

/* .dataGrid :global(.rdg-header-row) :global(.rdg-cell):last-child { */
/*   border-right: 1px solid var(--outline-gray); */
/* } */

.dataGrid :global(.rdg-header-row) :global(.rdg-cell) {
  /* border-bottom: 4px solid var(--outline-gray); */
  color: var(--primary-dark);
}

.dataGrid :global(.rdg-row) {
  background-color: var(--background-gray);
}

.dataGrid :global(.rdg-cell) {
  padding: 0 var(--space-xs);
  color: var(--primary-gray);
  font-weight: 500;
  border-block-end: 1px solid var(--outline-gray);
  border-inline-end: 1px solid var(--outline-gray);
}

.dataGrid :global(.rdg-cell):last-child {
  border-right: 0;
}

/* no interaction on cell */
.dataGrid :global(.rdg-cell[aria-selected="true"]) {
  outline: none;
}

.headerCell {
  position: relative;
}

.sortableHeaderCell {
  composes: headerCell;
  padding: var(--space-xs) 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1rem;
  text-wrap: wrap;
}
.sortableHeaderCell .label {
  display: block;
  overflow: auto;
  text-overflow: ellipsis;
}
.sortableHeaderCell .sortableLabel {
  margin-right: var(--space-xxs);
}

.filterIndicator {
  position: absolute;
  top: 0;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* for now always assuming */
.filterIndicator path {
  fill: var(--primary-blue);
}

.pinCell {
  position: relative;
  width: 100%;
  height: 100%;
}

.pinCell > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pinCell input {
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.pinCell label {
  width: var(--space-sm);
  height: var(--space-sm);
}

.pinCell label:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid var(--primary-dark);
  background-color: var(--primary-light);
}

.pinCell input:hover + label:before,
.pinCell input:checked + label:before {
  border-color: var(--primary-blue);
}

.nameCell {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  height: 100%;
  color: var(--blue-text);
}
.nameCell a {
  text-decoration: none;
}
.nameCell > a:hover > span {
  text-decoration: underline;
}

.nameCell > *:first-child:not(:last-child) {
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: var(--space-xxs);
}

.nameCell > span {
  display: block;
  height: 1rem;
  line-height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.unitCell {
  composes: text--xxs from "@syntensor/common/styles/typography.module.css";
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  text-transform: uppercase;
  height: 100%;
  flex-direction: column;
}

.centeredCell {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.valueCell {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 calc(-1 * var(--space-xs));
  color: var(--global-dark);
  text-align: right;
  text-transform: uppercase;
}

.halfValueCell {
  display: flex;
  flex: 1 0 50%;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--space-xs);
}

.hdHalfCellDark {
  color: var(--global-light);
}

.proteinCell {
  composes: valueCell;
  /* color: #ffffff; */
}

.proteinHCell {
  composes: proteinCell;
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
}

.proteinHalfCell {
  composes: halfValueCell;
  position: relative;
  padding: 0;
  text-align: center;
}

.proteinValue,
.proteinNormalizedValue {
  flex: 1 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.proteinValue {
}

.proteinNormalizedValue {
  position: relative;
}

.normalizedBackground,
.normalizedValue {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.normalizedValue {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 var(--space-xs);
}

.noDataValue {
  color: var(--primary-dark);
  opacity: 0.25;
}

.noColorScaleValue {
  color: var(--primary-dark);
}
