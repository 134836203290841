.treeItem {
  color: var(--primary-gray);
}
.treeItem:not(.treeItemTopLevel) {
  color: var(--blue-text);
}
.treeItem:not(:last-child) {
  border-bottom: 1px solid var(--outline-gray);
}

.treeItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2rem;
  padding: var(--space-xxs) var(--space-sm);
}

.treeItemHeader > *:not(:last-child) {
  margin-right: var(--space-sm);
}

.treeItemLabelWrapper {
  display: flex;
  align-items: center;
}

.treeItemLabel {
}

.nestedIcon {
  margin-right: var(--space-xs);
}

.btn {
  margin-right: calc(-1 * var(--space-lg));
  padding: var(--space-xxxs) var(--space-lg);
}
.btn::before {
  background-color: transparent;
}
.btn:active::before {
  border: 0 !important;
}

.btn path {
  fill: var(--outline-gray);
}

.treeItemActive,
.treeItem:not(.treeItemTopLevel):hover {
  background-color: var(--primary-blue);
  color: var(--global-light) !important;
  cursor: pointer;
}

.treeItemActive .nestedIcon path,
.treeItem:not(.treeItemTopLevel):hover .nestedIcon path {
  stroke: var(--global-light);
}

.treeItemActive .btn path,
.treeItem:not(.treeItemTopLevel):hover .btn path {
  fill: var(--global-light);
}
