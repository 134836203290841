.sortControl {
  position: absolute;
  height: 100%;
  top: 0;
  right: calc(var(--space-xs) * -1);
}

.sortButton {
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  border: 0;
  background-color: transparent;
}
.sortButton > svg {
  display: block;
}

.sortButton path {
  fill: var(--outline-gray);
}
.sortButton:hover:not(.activeSortButton) path {
  fill: var(--primary-dark);
}

.activeSortButton {
}
.activeSortButton path {
  fill: var(--primary-blue);
}

.activeSortUpButton path:nth-child(2) {
  fill: var(--primary-dark) !important;
}
.activeSortDownButton path:nth-child(1) {
  fill: var(--primary-dark) !important;
}

.upMultiDirButton,
.downMultiDirButton {
  position: absolute;
  right: 100%;
  width: var(--space-md);
  height: var(--space-md);
}

.upMultiDirButton {
  top: 0;
  transform: translate(-5px, -3px);
}

.downMultiDirButton {
  bottom: 0;
  transform: translate(0px, -4px);
}
