.comment {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
}

.clickableComment {
  cursor: pointer;
}
.clickableComment:hover {
  background-color: var(--background-gray);
}

.reply {
  border-left: var(--space-sm) solid var(--background-gray);
}

.inner {
  position: relative;
  padding: var(--space-sm);
}

.inner .inner {
  padding: 0;
  padding-left: var(--space-sm);
}

.resolved {
  opacity: 0.5;
}

.btns {
  position: absolute;
  top: var(--space-sm);
  right: var(--space-sm);
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
}
.comment:hover > * > .btns {
  visibility: visible;
}

.inner .inner .btns {
  top: calc(-1 * var(--space-md));
  right: 0;
}

.btn {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  opacity: 0.2;
  transition: 0.3s opacity;
}
.btn path {
  fill: var(--primary-gray);
}

.btn:hover {
  opacity: 1;
}
.btn:hover path {
  fill: var(--primary-dark);
}

.btnList {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.btnListHidden {
  visibility: hidden;
}

.btnsUpdating {
  visibility: visible;
}
.loader {
  left: auto;
}

.header {
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  display: flex;
  justify-content: space-between;
  margin: var(--space-lg) 0 var(--space-sm);
  color: var(--primary-gray);
}

.content {
  margin: var(--space-sm) 0;
}

.text {
  margin-bottom: var(--space-sm);
  line-height: 1.4;
}

.textValue {
  /* allow people to add blank lines into their comments */
  white-space: pre-wrap;
}

.textValue p {
  margin-bottom: 10px;
}

.text span {
  color: var(--blue-text);
}

.form input {
  padding: var(--space-xxs);
  border: 1px solid var(--outline-gray);
}

.textArea {
  width: 100%;
  background-color: transparent;
}

.editForm {
  margin-left: calc(-1 * var(--space-xs));
  margin-right: calc(-1 * var(--space-xs));
}

.replies {
  border-left: var(--space-sm) solid var(--background-gray);
}

.repliesList {
  list-style-type: none;
}

.replyForm {
  padding: var(--space-sm);
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-sm);
}

.footerBtn {
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  display: flex;
  align-items: center;
  padding-left: 0;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  color: var(--blue-text);
}

.footerBtn:hover {
  text-decoration: underline;
}
.footerBtn svg {
  margin-left: var(--space-xxxs);
}
.footerBtn path {
  fill: var(--blue-text);
}
