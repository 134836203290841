.pathwayDetailComponentDetail {
  position: relative;
  border-left: 1px solid var(--outline-gray);
  padding: var(--space-sm) var(--space-md);
}

.closeBtn {
  position: absolute;
  top: var(--space-sm);
  right: var(--space-sm);
}
.closeBtn > a {
  padding-right: var(--space-sm);
}

.closeBtn path {
  fill: var(--primary-dark);
}

.btns {
  margin-top: var(--space-sm);
  display: flex;
  justify-content: flex-end;
}
.btns > * {
  width: 12rem;
  white-space: nowrap;
}

.perturbations {
  margin-top: var(--space-lg);
}
