.header {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  display: flex;
  padding-top: var(--space-xxs);
}

.header::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--space-xxs);
  background-color: var(--background-gray);
  border-bottom: 1px solid var(--outline-gray);
}

.nav {
  flex: 1 0 auto;
}

.list {
  display: flex;
  list-style-type: none;
  margin: -1px;
  padding: 0;
}

.list > * {
  flex: 1 0 16.666%;
  margin-right: -1px;
}

.list > *:first-child > * {
  text-align: left;
}
