.title {
  composes: text--xl from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
}

.projectLink {
  color: var(--primary-gray);
  text-decoration: none;
}
.projectLink:hover {
  color: var(--primary-white);
}
