.privacyPolicy {
}

.privacyTerms {
  composes: privacyPolicy;
}

.container {
  max-width: 64rem;
  width: 100%;
  margin: 0 auto;
}

.headerTitle {
  font-family: var(--font-primary);
  font-size: 23px;
  color: var(--primary-dark);
  margin: var(--space-xxxxl) 0 var(--space-xxxxxxxl);
}

.logo {
  width: 96px;
  height: 72px;
  margin-bottom: 24px;
}

.content {
  width: 100%;
  overflow: auto;
  margin-bottom: var(--space-xxxxl);
  padding-top: var(--space-lg);
  border-top: 1px solid var(--outline-gray);
  color: var(--primary-gray);
}

.cols {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 900px) {
  .cols {
    flex-direction: row;
  }

  .cols > div:first-child {
    flex: 1 0 17.5rem;
    padding-right: var(--space-xl);
  }
}

.privacyPolicy h2,
.content h3,
.content h4,
.content h5 {
  color: var(--primary-dark);
}

.content h3,
.content h4,
.content h5 {
  font-family: var(--font-primary);
}

.privacyPolicy h2 {
  margin-bottom: var(--space-xl);
  font-size: var(--text-xxl);
}

.content h3 {
  font-size: var(--text-lg);
  margin-bottom: var(--space-lg);
}

.content h4 {
  font-size: var(--text-lg);
  margin-bottom: var(--space-md);
}

.content h5 {
  font-size: var(--text-md);
  margin-bottom: var(--space-sm);
}

.content hr {
  margin: var(--space-lg) 0;
  border: 0;
  border-bottom: 1px solid var(--outline-gray);
}

.content p {
  margin-bottom: var(--space-md);
}

.content a {
  color: var(--blue-text);
  text-decoration: none;
}
.content a:hover {
  text-decoration: underline;
}

.content table {
  margin-bottom: var(--space-md);
  border-collapse: collapse;
  border: 1px solid var(--outline-gray);
}

.content tr {
  border-bottom: 1px solid var(--outline-gray);
}

.content table th {
  text-align: left;
  color: var(--primary-dark);
  background-color: var(--background-gray);
}

.content table th,
.content table td {
  vertical-align: top;
  padding: var(--space-sm);
  border-right: 1px solid var(--outline-gray);
}

.content table td:last-child {
  border-right: 0;
}

.content table td:first-child {
  color: var(--primary-dark);
}

.content ul:not(.contentTable) {
  list-style-type: none;
  margin: var(--space-md) 0;
}

.content ul:not(.contentTable) li {
  margin-left: var(--space-sm);
  margin-bottom: var(--space-md);
}

.content ul:not(.contentTable) li::before {
  content: "\2022";
  color: var(--primary-dark);
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
}

.content ul:not(.contentTable) ul li::before {
  color: var(--primary-gray);
}

.content ul:not(.contentTable) ul li {
  margin-bottom: var(--space-xxxs);
}

.contentTableTitle {
  margin-bottom: var(--space-lg);
  font-family: var(--font-primary);
  font-size: var(--text-lg);
}

.contentTable ul {
  list-style-type: none;
}

.contentTable li {
  padding-bottom: var(--space-md);
}

.contentTable a {
  color: var(--blue-text);
  text-decoration: none;
}
.contentTable a:hover {
  text-decoration: underline;
}

.footer {
  padding-bottom: var(--space-xxxl);
}

.lastUpdated {
  margin-bottom: var(--space-md);
  margin-top: var(--space-md);
  color: var(--primary-dark);
}

.termsFooter {
  display: flex;
}
.termsFooter > *:not(last-child) {
  padding-right: var(--space-sm);
}

.privacyFooter {
  width: 100%;
  margin-top: var(--space-xxl);
  padding: var(--space-xxl) 0;
  border-top: 1px solid var(--outline-gray);
}

.privacyPolicy :global(.large-link) {
  display: block;
  margin-bottom: var(--space-sm);
  font-size: var(--space-md);
  color: var(--primary-blue);
}
