.privacyFooter {
  display: flex;
}

.privacyFooter > div {
  flex: 1 0 33%;
}

.subtitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
  color: var(--primary-dark);
  margin-bottom: var(--space-sm);
}

.list {
  list-style-type: none;
}

.listLink {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  text-decoration: none;
  color: var(--primary-gray);
}
.listLink:hover {
  text-decoration: underline;
}

.linkHighlight {
  composes: listLink;
  color: var(--red-text);
}
