.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: var(--primary-light);
  bottom: 0;
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
}
