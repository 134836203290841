.profile {
  composes: oneColGrid from "@syntensor/common/styles/grid.module.css";
  min-height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.title {
  composes: text--xxl from "@syntensor/common/styles/typography.module.css";
  margin-bottom: var(--space-sm);
  color: var(--primary-gray);
}
.title strong {
  font-weight: 500;
  color: var(--primary-dark);
}

.subtitle {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  color: var(--primary-gray);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 40rem;
  width: 100%;
  margin: auto;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.login,
.forgotPassword,
.changePassword,
.changeDetails,
.privacy,
.welcome,
.signout {
  composes: profile;
}

.privacy,
.welcome,
.signout {
  margin: var(--space-lg) 0;
}

.row {
  display: flex;
  width: 100%;
  margin-bottom: var(--space-md);
}
.row > * {
  flex: 1 1 auto;
}
.row > *:not(:last-child) {
  padding-right: var(--space-sm);
}

.buttonRow {
  composes: row;
  padding-right: 66%;
}

.bottomRow {
  color: var(--primary-gray);
}

.description {
  composes: row;
  color: var(--primary-gray);
}

.successMsg {
  color: var(--green-text);
}

.errorMsg {
  color: var(--red-text);
}

.link {
  color: var(--primary-gray);
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}

.headerTitle {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
  margin-bottom: var(--space-lg);
}

.logo {
  width: 6rem;
  height: 4.5rem;
}

.textContent {
}
.textContent p {
  margin: var(--space-sm) 0;
}
.textContent h5 {
  color: var(--primary-gray);
}

.detailsFooter {
  width: 100%;
  margin-top: var(--space-xxl);
  padding-top: var(--space-xxl);
  border-top: 1px solid var(--outline-gray);
}

.termsFooter {
  display: flex;
}
.termsFooter > *:not(last-child) {
  padding-right: var(--space-sm);
}

.checkbox {
  margin-right: var(--space-sm);
  transform: scale(1.2);
}
