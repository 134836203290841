.suggestionsList {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: var(--primary-light);
  z-index: 2;
  transform: translate(0, var(--space-sm));
}

.list {
  max-height: calc(9 * 4rem);
  overflow: auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid var(--primary-gray);
}

.item {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  border-bottom: 1px solid var(--outline-gray);
}
.item:last-child {
  border-bottom: 0;
}
.item button {
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  appearance: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.item:hover,
.itemSelected {
  color: var(--primary-light) !important;
  background-color: var(--primary-dark);
}

/* change highlight blue to the dark-mode blue for better readability */
.item:hover > div > div:first-child > span,
.itemSelected > div > div:first-child > span {
  color: #76a5ff;
}
