.proteinRender {
  width: 100%;
  height: 100%;
  background-image: url("./protein_light@2x.png");
  background-size: cover;
}

html[data-theme="dark"] .proteinRender {
  background-image: url("./protein_dark@2x.png");
}
