.viewToggle {
  display: flex;
}

.viewToggle > li {
  margin-right: -1px;
}

.btn {
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
}

.mainNavBtn {
}

.btn path,
.btn rect {
  fill: var(--primary-dark);
}

/* if active, override button subtle settings */
.btnActive {
  color: var(--primary-light) !important;

  /*
  make sure if in group, the active button is on top
  to have its border visible
  */
  position: relative;
  z-index: 1;
}

.btnActive::before {
  background-color: var(--primary-dark) !important;
  border: 1px solid var(--primary-dark) !important;
}

.mainNavBtn.btnActive {
  color: var(--global-light) !important;
}

.mainNavBtn.btnActive::before {
  background-color: var(--primary-blue) !important;
  border: 1px solid var(--primary-blue) !important;
}

.btnActive path,
.btnActive rect {
  fill: var(--primary-light) !important;
}

.btn:hover path,
.btn:hover rect {
  fill: var(--primary-gray) !important;
}
