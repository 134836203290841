.searchForm {
  position: relative;
  width: 100%;
}

.btns {
  flex: 0 1;
  display: flex;
  margin-left: var(--space-sm);
}

.btns button {
  width: 8rem;
  padding: calc(var(--space-xs) - 1px) var(--space-xxxs);
}

.cancelBtn {
  margin-left: var(--space-xs);
}

.input {
  display: flex;
}

.input > *:first-child {
  flex: 5 1 auto;
}
