.metricsBarChart {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
}

.row,
.header,
.footer {
  display: flex;
  width: 100%;
}

.header,
.footer {
  color: var(--primary-gray);
}

.header {
  text-transform: uppercase;
  border-bottom: 1px solid var(--outline-gray);
}

.headerCell {
  padding: var(--space-xs);
  text-align: center;
}
.headerCell:not(:last-child) {
  border-right: 1px solid var(--outline-gray);
}
.headerCell:not(:last-child):nth-child(2) {
  width: 6%;
}
.headerCell:last-child {
  text-align: left;
}

.name,
.headerCell:first-child {
  flex-basis: 28%;
  padding: var(--space-xs);
  text-align: right;
  border-right: 1px solid var(--outline-gray);
}

.name {
  color: var(--primary-dark);

  /* always have border so that height doesn't change if chart has leftLabel */
  border-bottom: 1px solid transparent;
}

.leftLabel {
  width: 6%;
  padding: var(--space-xs);
  text-align: center;
  color: var(--blue-text);
  border-right: 1px solid var(--outline-gray);
  border-bottom: 1px solid var(--outline-gray);
  border-top: 0;
}
.row:last-child .leftLabel {
  border-bottom: 0;
}

.rightLabel {
  position: absolute;
  transform: translate(0%, -50%);
  top: 50%;
  padding: var(--space-xxs);
}

.rightLabelRightAnchor {
  transform: translate(-100%, -50%);
  color: var(--primary-white);
}

.boxPlotLabels {
  color: var(--primary-blue);
  opacity: 70%;
}

.bars {
  display: flex;
  position: relative;
  flex: 1 0 auto;
}

.bar {
  display: block;
  height: 100%;
  background-color: var(--primary-blue);
  opacity: 70%;
}
.row:not(:last-child) .bar {
  border-bottom: 1px solid var(--primary-white);
}

.bar:not(.stackedBar) {
  position: absolute;
  left: 0;
}

.header,
.footer {
  /* caps font */
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
}

.footer {
  display: flex;
  width: 100%;
  border-top: 1px solid var(--outline-gray);
}

.axisLabel {
  flex-basis: 28%;
  padding: var(--space-xs);
  text-align: center;
  text-transform: uppercase;
}
.axisLeftLabel {
  flex-basis: 6%;
}
.axis {
  position: relative;
  flex: 1 0 auto;
}

.axisSvg {
  width: 100%;
  height: 50px;
}

.axisSvg :global(.domain) {
  stroke: none;
}
.axisSvg :global(.tick):first-of-type :global(text) {
  text-anchor: start;
}
.axisSvg :global(.tick):last-of-type :global(text) {
  text-anchor: end;
}

.boxPlot {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(245, 245, 245);
}
.row:nth-child(2n) .boxPlot {
  background-color: var(--primary-white);
}

.boxPlotInner {
  width: 100%;
  height: 24px;
}
