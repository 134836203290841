.project {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.cellLines {
}

.header {
  flex: 0 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-sm);
  border-bottom: 1px solid var(--outline-gray);
}

.title {
  composes: text--xl from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-sm);
  border-bottom: 1px solid var(--outline-gray);
}

.subcontent {
  padding: var(--space-sm);
}

.list {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--space-sm);
  grid-row-gap: var(--space-sm);
  flex-wrap: wrap;
  grid-auto-rows: 1fr;
}

.list > * {
  flex: 0 1 50%;
  margin-bottom: var(--space-sm);
}
