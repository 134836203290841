.toggle {
  display: flex;
  cursor: pointer;
}

.input {
  display: none;
}

.label {
  margin-right: 5px;
  font-size: 12px;
  white-space: nowrap;
}

.graphic {
  position: relative;
  display: block;
  width: var(--space-xxxl);
  height: var(--space-lg);
  background-color: var(--primary-gray);
  border-radius: var(--space-xl);
}

.graphicDot {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--space-lg);
  height: var(--space-lg);
  border: 2px solid var(--primary-gray);
  background-color: var(--primary-light);
  border-radius: 50%;
  transition: 0.2s transform;
}

.toggle:hover .graphic {
  background-color: var(--primary-gray);
}
.toggle:hover .graphicDot {
  border: 2px solid var(--primary-gray);
}

/* Checked theme */
.input:checked + .graphic {
  background-color: var(--primary-blue);
}
.input:checked + .graphic > .graphicDot {
  border-color: var(--primary-blue);
  transform: translate(calc(var(--space-xxxl) - var(--space-lg)), 0);
}
.toggle:hover .input:checked + .graphic {
  background-color: var(--primary-blue);
}
.toggle:hover .input:checked + .graphic > .graphicDot {
  border-color: var(--primary-blue);
}

/* Small theme */
.small .graphic {
  width: var(--space-lg);
  height: 1rem;
}

.small .graphicDot {
  width: 1rem;
  height: 1rem;
}

.small .input:checked + .graphic > .graphicDot {
  transform: translate(calc(var(--space-lg) - 1rem), 0);
}

/* Disabled */
.input:disabled + .graphic {
  background-color: var(--shape-gray);
  cursor: not-allowed;
}
.input:disabled + .graphic > .graphicDot {
  border-color: var(--shape-gray);
}