.moaRank {
  display: flex;
  justify-content: space-between;
}

.label,
.value {
  display: block;
  padding: 0 var(--space-xxxs);
}

.label {
}

.value {
  background-color: var(--primary-dark);
  color: var(--primary-light);
}

.small {
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  height: var(--space-md);
  line-height: var(--space-md);
  border-radius: 2px;
}

.large {
  composes: text--md from "@syntensor/common/styles/typography.module.css";
  height: var(--space-lg);
  line-height: var(--space-lg);
  border-radius: 4px;
}

.large .label,
.large .value {
  padding: 0 var(--space-xxs);
}

/* PRIMARY THEME */
.primary {
  color: var(--blue-text);
  border: 1px solid var(--blue-text);
}

.primary .value {
  background-color: var(--blue-text);
}

/* SECONDARY THEME */
.secondary {
  color: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}
