.explorer {
  position: absolute;
  border: 1px solid var(--outline-gray);
  top: var(--space-lg);
  left: var(--space-sm);
  right: var(--space-sm);
  bottom: var(--space-lg);
  padding: 1px;
  background-color: var(--primary-light);
  overflow: hidden;
}

.explorerInner {
  display: flex;
}

.explorerInner > * {
  /* assuming up to 3 columns */
  flex: 1 1 33%;
}

.hierarchy {
  flex-grow: 0;
  border-right: 1px solid var(--outline-gray);
  overflow: auto;
}

.hierarchyBtn {
  padding: calc(var(--space-xs) - 1px);
}
.hierarchyBtn:hover path,
.hierarchyBtn:hover rect {
  fill: var(--primary-gray) !important;
}

.hierarchyBtn::before {
  background-color: var(--primary-light);
  border: 1px solid var(--outline-gray);
}
.hierarchyBtn path,
.hierarchyBtn rect {
  fill: var(--primary-dark);
}

.hiearchyBtnActive::before {
  background-color: var(--primary-dark) !important;
}
.hiearchyBtnActive path,
.hiearchyBtnActive rect {
  fill: var(--primary-light);
}

.control {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}
.control:hover .controlContent {
  visibility: visible;
}

.topLeftControl {
  composes: control;
  top: var(--space-sm);
  left: var(--space-sm);
}

.topLeftControl > *:not(:last-child) {
  margin-right: var(--space-xs);
}

.topRightControl {
  composes: control;
  top: var(--space-sm);
  right: var(--space-sm);
}

.bottomLeftControl {
  composes: control;
  bottom: var(--space-sm);
  left: var(--space-sm);
}

.controlIcon path {
  fill: var(--global-light) !important;
}
.controlContent {
  display: block;
  visibility: hidden;
}

.canvasWrapper {
  position: relative;

  /* we might have an overlay which needs cropping */
  overflow: hidden;
}

.canvas {
  width: 100%;
  height: 100%;
}

.tooltip {
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
