.study {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.comments {
  position: absolute;
  top: 4rem;
  right: 0px;
  bottom: 0px;
  z-index: 10;

  /* transform: translate(100%, 0); */
  transform: translate(0%, 0);
}

.header {
  position: relative;
  flex: 0 0 auto;
}

.search {
  position: absolute;
  top: var(--space-xxs);
  left: 0;
  right: 0;
  z-index: 3;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
.content > * {
  flex: 0 0 auto;
}
.content > *:last-child {
  flex: 1 0 auto;
}

.titles {
  composes: text--xl from "@syntensor/common/styles/typography.module.css";
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* make sure we're aligned with the sidebar */
  min-height: 4.1rem;

  padding: var(--space-sm);
  border-bottom: 1px solid var(--outline-gray);
}

.subtitle {
  composes: text--lg from "@syntensor/common/styles/typography.module.css";
  font-family: var(--font-primary);
}

.controls {
  display: flex;
  flex-direction: column;
}

.controlRow {
  display: flex;
  align-items: center;
}
.controlRow > * {
  flex: 1 0 auto;
}
.controlRow > *:not(:last-child) {
  margin-right: var(--space-sm);
}

.mainControl {
  flex: 4 1 auto;
}

.noGrowControl {
  flex: 0 0 auto;
}

.rightSideControls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.rightSideControls > div {
  margin-bottom: var(--space-sm);
}

.sectionHeader {
  padding: var(--space-sm);

  /* we might have filters which are pushing from the bottom */
  position: relative;
  z-index: 1;
}

.sectionContent {
  position: relative;
  padding: 0 var(--space-sm);
}

.cols {
  display: flex;
  flex: 1 0 auto;
}
.cols > * {
  flex: 1 0 auto;
  padding: var(--space-sm);
}
.cols > *:first-child {
  flex: 0 0 250px;
  border-right: 1px solid var(--outline-gray);
}

.headerCols {
  composes: cols;
  flex: 0 0 auto;
}

.showLegendBtn {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
}

.subpageHeader {
  margin: var(--space-sm) 0;
}

.headerTitleWrapper {
  display: flex;
}

.tooltip {
  max-width: 10rem;
  margin-right: var(--space-sm);
}

.loadMoreBtn,
.footerBtn {
  display: flex;
  justify-content: flex-end;
  margin: var(--space-xs) 0;
}
.loadMoreBtn > * {
  width: 12rem;
}

.linkCell {
  color: var(--blue-text);
  text-decoration: none;
}

.linkCell:hover {
  text-decoration: underline;
}

.dataGridHeader {
  display: flex;
  justify-content: space-between;
  margin: 0 0 var(--space-sm);
}

.dataGridHeader > div {
  display: flex;
}

.dataGridHeader > div:first-child {
  display: flex;
  flex-wrap: wrap;
}

.dataGridFilters {
  flex: 1 0 50%;
  flex-wrap: wrap;
}

.dataGridBtn {
  padding: var(--space-xs) var(--space-sm);
  white-space: nowrap;
}
.dataGridBtn rect,
.dataGridBtn path {
  fill: var(--primary-dark);
}

.dataGridIcons {
  /*
    data grid header might be pushed up with negative margin
    so need to make sure the buttons are still clickable
  */
  position: relative;
  z-index: 1;
}

.dataGridIconBtn {
  composes: dataGridBtn;
  composes: text--xs from "@syntensor/common/styles/typography.module.css";
  position: relative;
  top: var(--space-sm);
  padding: var(--space-xs) var(--space-xxxs);
}
.dataGridIconBtn svg {
  margin-right: var(--space-xxxs);
}
.dataGridIconBtn:not(:last-child) {
  margin-right: var(--space-xxs);
}

.emptyResult {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  margin: var(--space-sm) 0;
}

.valueCell {
  composes: text--sm from "@syntensor/common/styles/typography.module.css";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 calc(-1 * var(--space-xs));
  color: var(--global-dark);
  text-align: right;
  text-transform: uppercase;
}

/* .halfValueCell {
  display: flex;
  flex: 1 0 50%;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--space-xs);
} */

.valueCellDark {
  color: var(--global-light);
}

.tableValueCell {
  composes: valueCell;
  margin: calc(-1 * var(--space-xs));
  padding: var(--space-sm);
}

.baseLineExpresssionCell {
  composes: valueCell;
  color: var(--primary-dark);
}

.legend {
  display: flex;
  justify-content: flex-end;
  margin: var(--space-sm) 0;
  padding: var(--space-sm);
  background-color: var(--background-gray);
  border: 1px solid var(--outline-gray);
}
