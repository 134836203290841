.submitBtn {
  position: relative;
  width: 100%;
}

.content {
  display: block;
}

.contentLoading {
  composes: content;
  opacity: 0;
}