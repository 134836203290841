.popup {
  position: relative;
  width: 100%;
  padding: var(--space-xl);
  border: 1px solid var(--primary-dark);
  background-color: var(--primary-light);
}
.popup.hasMaxWidth {
  max-width: 36rem;
}

.closeBtn {
  position: absolute;
  top: var(--space-md);
  right: var(--space-sm);
}

.closeBtn svg {
  fill: var(--primary-dark);
}
